let watcher = {
    update: function (manager, eventType, data) {
        if (eventType === 'saveConsents') {
            console.log(data.type)
            // si un des consentements a été retiré, on recharge la page
            if (Object.values(data.changes).includes(false)) {
                (window as Window).location.reload();
            };
            manager.unwatch(watcher);
        }
    }
}

export const handleConsentChange = (Klaro: any) => {
    let manager = Klaro.getManager() // get the consent manager
    manager.watch(watcher) //

    // display modal
    Klaro.show()
}