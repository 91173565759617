import { Routes } from 'lib/routes';
import { SocialLinks } from 'lib/social-links';
import Link from 'next/link';
import React from 'react'
import { useTranslation } from 'next-i18next';
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import Image from '../ui/image/Image'
import { handleConsentChange } from 'lib/klaro/change-handler';

interface Props {
    questions?: boolean;
}

const Footer: React.FC<Props> = ({
    questions = true
}) => {

    const { t } = useTranslation();

    const links: {
        label: string;
        link?: string;
        function?: (e: any) => void;
    }[] = [
            {
                label: t('common:footer.contact'),
                link: Routes.CONTACT
            },
            {
                label: t('common:footer.termsAndConditions'),
                link: Routes.TERMS_AND_CONDITIONS
            },
            {
                label: t('common:footer.aboutUs'),
                link: Routes.ABOUT_US
            },
            {
                label: t('common:footer.cookies'),
                function: (e: any) => {
                    e.preventDefault();
                    const Klaro = (window as any).klaro;
                    if (Klaro) {
                        handleConsentChange(Klaro);
                    }
                }
            }
        ]

    return (
        <>
            {questions && (
                <footer className="py-16 text-center bg-gray-100 lg:ml-auto">
                    <h2 className="mb-4 text-2xl font-bold lg:mb-8 ">
                        {t('common:footer.title')}
                    </h2>
                    <div className="flex flex-wrap justify-around w-2/3 mx-auto lg:w-1/2">
                        {/* <div className="order-1 w-full h-full text-gray-700 lg:w-1/2 ">
                            <p className="mb-2 lg:mx-5 lg:my-5">
                                {t('common:footer.seeFAQText')}
                            </p>
                        </div>
                        <div className="order-3 w-full h-full text-gray-700 lg:order-2 lg:w-1/2 ">
                            <p className="mb-2 lg:mx-5 lg:my-5">
                                {t('common:footer.contactUsText')}
                            </p>
                        </div> */}
                        <div className="order-2 w-full lg:order-3 lg:w-1/2">
                            <Link passHref href={Routes.FAQ}>
                                <a className="w-56 mx-auto mb-6 lg:my-5 btn">
                                    {t('common:footer.seeFAQButton')}
                                </a>
                            </Link>
                        </div>
                        <div className="order-4 w-full lg:w-1/2 ">
                            <Link passHref href={Routes.CONTACT}>
                                <a className="w-56 mx-auto mb-2 lg:my-5 btn">
                                    {t('common:footer.contactUsButton')}
                                </a>
                            </Link>
                        </div>
                    </div>
                </footer>
            )}

            <footer className="grid justify-between max-w-5xl grid-cols-1 grid-rows-3 py-4 pl-8 mx-auto space-y-8 lg:space-y-0 auto-rows-min lg:grid-cols-4 lg:grid-rows-1 lg:py-16">
                <div className="flex flex-col justify-start lg:col-span-2 place-self-center justify-self-start ">
                    <Image src="/images/logo/tt-logo.png" alt="Teacher Tribe Logo" width={258} height={56} />
                    <span className="ml-20 text-sm">Where teachers find jobs</span>
                </div>
                <div className="flex flex-col lg:place-self-center lg:place-items-center">
                    <span className="mb-4 text-lg font-bold">
                        {t('common:footer.followUs')}
                    </span>
                    <div className="flex flex-row space-x-2">
                        <a href={SocialLinks.FACEBOOK} target="_blank" rel="noopener"><FaFacebookSquare className="text-3xl" /></a>
                        <a href={SocialLinks.INSTAGRAM} target="_blank" rel="noopener"><FiInstagram className="text-3xl" /></a>
                        <a href={SocialLinks.LINKEDIN} target="_blank" rel="noopener"><FaLinkedin className="text-3xl" /></a>
                        <a href={SocialLinks.TWITTER} target="_blank" rel="noopener"><FaTwitter className="text-3xl" /></a>
                    </div>
                </div>
                <div className="flex flex-col justify-around space-y-6 lg:place-self-end">
                    {links.map(link => {
                        if (!!link.link) {
                            return (
                                <Link passHref href={link.link} key={link.label} >
                                    <a className="text-sm">{link.label}</a>
                                </Link>
                            )
                        }
                        if (!!link.function) {
                            return (
                                <a key={link.label} onClick={link.function} className="text-sm cursor-pointer">{link.label}</a>
                            )
                        }
                        return null;
                    })}
                </div>
            </footer>

        </>
    )
}

export default Footer;